
import {defineComponent, onMounted, reactive, toRefs} from "vue";
import ApiLogisticsAudit from "@/request/apis/api_logistics_audit";
import {IDriveLicenceItem, ILicenceItem} from "@/models/logistics_model";
import {useRoute} from "vue-router";
import {dateFormat} from "@/utils/common";
import {message} from "ant-design-vue/es";

interface IState {
  detail?: ILicenceItem
  visible: boolean
  reason: string
  loading: boolean
  pass: boolean
  carType: any[]
  energyType: {value: string; text: string}[]
  colorType: {value: string; text: string}[]
}

export default defineComponent({
  name: "DrivingLicenceDetail",
  setup() {
    let editId: any;
    const route = useRoute()

    const state: IState = reactive({
      detail: undefined,
      visible: false,
      reason: '',
      loading: false,
      pass: false,
      carType: [],
      energyType: [],
      colorType: []
    })

    // 获取文章详情
    const getDetail = async () => {
      const {id} = route.query
      if (id) {
        editId = id
        const res = await ApiLogisticsAudit.getLicenceDetail(id.toString())
        if (res && res.error_code === 0 && res.data) {
          const data = res.data
          state.detail = data
        }
      }
    }

    // 车辆类型
    const getCarType = async () => {
      const res = await ApiLogisticsAudit.getCarType()
      if(res && res.error_code === 0){
        state.carType = res.data || []
      }
    }

    const renderCarType = (vehicle_travel_type: any) => {
      const item = state.carType.find(e => e.code === vehicle_travel_type)
      if(item){
        const alias = item.alias;
        return `${item.name}${alias ? `(${alias})` : ''}`
      }
      return '';
    }

    // 能源类型
    const getVehicleEnergyType = async () => {
      const res = await ApiLogisticsAudit.getVehicleEnergyType()
      if(res && res.error_code === 0){
        state.energyType = res.data || []
      }
    }

    const renderEnergy = (vehicle_energy_type: any) => {
      const item = state.energyType.find(e => e.value === vehicle_energy_type)
      return item?.text || ''
    }

    // 车牌颜色
    const getVehicleColor = async () => {
      const res = await ApiLogisticsAudit.getVehicleColor()
      if(res && res.error_code === 0){
        state.colorType = res.data || []
      }
    }

    const renderColor = (vehicle_color: any) => {
      const item = state.colorType.find(e => Number(e.value) === Number(vehicle_color))
      return item?.text || ''
    }


    // 审核
    const onAudit = async () => {
      const flg = state.pass
      if (!flg && !state.reason) {
        message.destroy()
        message.warning('请输入不通过的原因')
        return
      }
      state.loading = true
      const res = await ApiLogisticsAudit.auditLicence({
        "courier_application_id": state.detail?.id,
        "reason": flg ? "审核通过" : state.reason,
        "is_passed": flg
      })
      state.loading = false
      if (res && res.error_code === 0) {
        message.success('操作成功')
        await getDetail()
      } else {
        message.error(res?.msg || '审核失败');
      }
      state.visible = false
      state.reason = ''
    }

    // 设置modal显示隐藏
    const setVisible = (visible: boolean, pass: boolean) => {
      state.visible = visible
      state.pass = pass
      state.reason = ''
    }

    onMounted(async () => {
      await getVehicleColor();
      await getVehicleEnergyType();
      await getCarType();
      await getDetail()
    })

    return {
      ...toRefs(state),
      dateFormat,
      setVisible,
      onAudit,
      renderCarType,
      renderEnergy,
      renderColor,
    }
  }
})
